.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background: url("../assets/images/error.jpg");
  background-size: cover;
  background-position: center;
}

.not-found-title {
  font-size: 36px;
  color: #1619a9;
  margin-bottom: 20px;
}

.not-found-message {
  font-size: 18px;
  color: #373fa8;
  margin-bottom: 40px;
}

.not-found-icon {
  font-size: 150px;
  color: red;
}
