.modal {
  height: 1000px;
  position: relative;
}

.cart__color {
  background-color: #bebcbd;
}

.order-details-row {
  background-color: #d5deff;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.order-details {
  padding: 10px;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.order-details table {
  width: 100%;
  margin-bottom: 0;
  color: #212529;
}

.order-details th,
.order-details td {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.order-details thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.order-details tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.users-billing-info {
  margin: 10px;
}

.users-billing-info h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.users-billing-table {
  width: 100%;
  border-collapse: collapse;
}

.users-billing-table td {
  padding: 8px;
  border: 1px solid #978d8d;
}

.users-billing-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.users-billing-table strong {
  font-weight: bold;
}
