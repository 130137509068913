.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* Adjust the alignment as needed */

  background-color: white;
}

.main-content {
  flex-grow: 1;
  overflow-y: auto;
  max-width: 1700px;
  /* Adjust the max-width as needed */
  width: 100%;
  height: 100%;
}
/* Adjust the gap between sidebar and main content */
