.product__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgba(20, 7, 48, 0.1);
  overflow: hidden;
  height: 100%;
  margin-bottom: 20px;
}

.product__item .product__placeholder {
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.product__item .product__placeholder:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f2f2f2 0%, #e0e0e0 50%, #f2f2f2 100%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s ease-in-out infinite;
}

.product__item.product__placeholder .product__img {
  height: 260px;
  background-color: #e0e0e0;
}

.product__item.product__placeholder .product__info {
  margin-top: 10px;
}

.product__item.product__placeholder .product__name-placeholder {
  height: 20px;
  background-color: #e0e0e0;
}

.product__item.product__placeholder .product__category-placeholder {
  height: 12px;
  background-color: #e0e0e0;
  margin-top: 5px;
}

.product__item.product__placeholder .product__card-buttom-placeholder {
  height: 30px;
  background-color: #e0e0e0;
  margin-top: 10px;
}

.product__item .product__img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.product__name {
  width: 100%;
  height: 38px;
}

.product__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  height: 100%;
}

.product__info h3 a {
  font-size: 0.85rem;
  color: var(--primary-color);
  font-weight: 600;
  margin-top: 10px;
  text-decoration: none;
}

.product__info a:hover {
  color: inherit;
}

.product__category {
  font-size: 0.9rem;
  margin-top: auto;
}

.product__rating {
  margin-top: 5px;
  font-size: 0.9rem;
  color: coral;
}

.product__card-buttom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 30px;
}

.product__card-buttom .price {
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 500;
}

.product__card-buttom span i {
  font-size: 1.2rem;
  padding: 5px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 50px;
}

@media only screen and (max-width: 992px) {
  .product__info h3 a {
    font-size: 0.8rem;
  }

  .product__card-buttom {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .product__info h3 a {
    font-size: 0.8rem;
  }

  .product__card-buttom {
    margin-top: 10px;
  }
  .product__card-buttom span i {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 576px) {
  .product__container {
    width: 82%;
    margin-left: 8.95%;
  }

  .product__item {
    flex-direction: column;
  }

  .product__item .product__img {
    border-radius: 8px 8px 0 0;
    height: 230px;
  }

  .product__name {
    height: auto;

    padding-bottom: 0px;
  }

  .product__info {
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .product__info h3 a {
    font-size: 1rem;
  }

  .product__category {
    font-size: 0.9rem;
  }

  .product__rating {
    font-size: 0.9rem;
    font-weight: bold;
  }

  .product__card-buttom {
    align-items: flex-start;
  }

  .product__card-buttom .price {
    font-size: 1rem;
  }
}
