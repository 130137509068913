.address-content__title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.address-card {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.address-card_country {
  display: flex;
  align-items: center;
  gap: 10px;
}

.address-card__dob-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .address-card {
    max-width: 100%;
    padding: 10px;
  }
}
