.billing__form .form__group {
  border: 1px solid var(--card-bg-02);
}

.checkout__cart {
  padding: 20px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px;
}

.checkout__cart h6,
h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkout__cart h6 {
  margin-bottom: 20px;
}

.checkout__cart h4 {
  border-top: 1px solid rgba(221, 221, 221, 0.253);
  padding-top: 20px;
}

.checkout__cart button {
  background: white;
  color: var(--primary-color);
  font-weight: 600;
}

@media (max-width: 767px) {
  .billing__form .form__group {
    margin-bottom: 20px;
  }

  .checkout__cart {
    padding: 10px;
    font-size: 14px;
  }

  .checkout__cart h6 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .checkout__cart h4 {
    padding-top: 10px;
    font-size: 18px;
  }

  .checkout__cart button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .checkout__cart .spinner {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .checkout__cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .checkout__cart h6,
  .checkout__cart h4 {
    width: 100%;
  }

  .checkout__cart button {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .billing__form .form__group {
    width: 100%;
  }
}
