/* Hero Section */
.hero__section {
  padding: 10px;
  background-image: url("../assets/images/hero.jpg");
  background-position: center;
  background-size: 85%;
  background-repeat: no-repeat;
  height: 500px;
}

.hero__content {
  position: relative;

  padding: 0;
  color: #827a7a; /* Set the text color to a contrasting color for better visibility */
}

.hero__content h2 {
  background-color: #0d0d0d42;
  color: rgb(221, 255, 217);
  font-size: 2.7rem;
  font-weight: 700;
  margin: 20px 0px;
}

.hero__content p {
  background-color: #0000002e;
  color: rgba(254, 255, 254, 0.901);

  line-height: 28px;
  font-size: 1.5rem;
}

.hero__section p:nth-child(1) {
  font-weight: 500;
}

.buy__btn {
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 40px;
}

.buy__btn a:hover {
  color: #fff;
}

.hero__content {
  padding-top: 45px;
}

.timer__count {
  background: #253b80;
  height: 330px;
  padding: 20px;
}

.timer__count Container {
  margin-top: 40px;
}

.timer__count h3 {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.9rem;
  margin-top: 40px;
}

.timer__count button {
  width: 180px;
  height: 55x;
  font-weight: bold;
  font-size: 1.2rem;
}

.store__btn {
  background: #fff;
  color: var(--primary-color);
  font-weight: 600;
}

.store__btn a:hover {
  color: var(--primary-color);
}

.arrow-icon {
  width: 50px;
}

/* Media Queries */
@media only screen and (max-width: 1200px) {
  .hero__content h2 {
    font-size: 2.2rem;
    margin-bottom: 10px;
    /* color: rgb(162, 239, 166); */
  }

  .hero__content p {
    font-size: 1.5rem;
    line-height: 24px;
    /* color: black; */
  }

  .buy__btn {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 992px) {
  .hero__section {
    background-size: cover;
  }
  .hero__content h2 {
    font-size: 1.8rem;
    /* color: var(--primary-color); */
  }

  .hero__content p {
    /* color: var(--primary-color); */
    font-size: 1.3rem;
  }

  .section__title {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 768px) {
  .hero__section {
    height: 400px;
    background-size: cover;
  }

  .hero__content h2 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }

  .hero__content p {
    font-size: 1.1rem;
    line-height: 22px;
  }

  .buy__btn {
    font-size: 0.8rem;
  }

  .section__title {
    font-size: 1.4rem;
  }

  .timer__count {
    height: 300px;
    padding-top: 10px;
  }
  .clock__top-content h4 {
    font-size: 0.8rem !important;
    margin-left: 310px;
  }
}

@media only screen and (max-width: 576px) {
  .hero__section {
    height: 350px;
    background-size: cover;
  }

  .hero__content {
    margin-bottom: 0px;
    top: 35px;
    width: 310px;
  }

  .hero__content h2 {
    font-size: 1.5rem;
    margin-bottom: 8px;
    /* color: rgb(1, 70, 46); */
  }

  .hero__content p {
    font-size: 1rem;
    line-height: 20px;
    /* color: rgb(221, 255, 217); */
  }

  .buy__btn {
    font-size: 0.7rem;
    padding: 6px 16px;
    margin-top: 30px;
    font-weight: bold;
  }

  .section__title {
    font-size: 1.2rem;
  }

  .timer__count {
    height: 250px;
  }

  .timer__count h3 {
    color: #ffffff;
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 40px;
  }
}
