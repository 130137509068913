/* CSS for Product Details Page */

.product__images {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product__images img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}

.product__images img.selected {
  border: 2px solid blue;
}

.selected-image img {
  width: 550px;
  height: 400px;
  object-fit: cover;
}

.review__section {
  padding-top: 0;
}

.product__details {
  margin-top: 70px;
}

.product__details h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  color: #333;
}

.product__rating span i {
  color: coral;
}

.product__rating p span {
  color: coral;
  font-weight: 500;
}

.product__price {
  font-size: 1.3rem;
}

.tab__wrapper {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.active__tab {
  font-weight: 600;
}

.tab__content p {
  line-height: 30px;
}

.review__wrapper ul .review__item {
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.307);
  margin-bottom: 20px;
  margin-right: 20px;
  width: 95%;
}

.review__wrapper ul {
  padding-left: 0;
}

.review__wrapper ul li {
  margin-bottom: 10px;
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-right: 10px;
  box-sizing: border-box;
}

.review__wrapper ul li .review__username {
  color: #333;
  font-weight: 600;
}

.review__wrapper ul li .review__stars {
  color: coral;
}

.review__wrapper ul li .review__text {
  color: #666;
}

.review__form {
  width: 70%;
  margin: auto;
  margin-top: 50px;
}

.review__form h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 30px;
  color: #333;
}

.form__group input,
.form__group textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 20px;
  background-color: #f5f5f5;
  color: #333;
}

.form__group input:focus,
.form__group textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.form__group {
  margin-bottom: 30px;
}

.form__group span {
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: rgb(79, 60, 60);
  font-weight: 600;
  cursor: pointer;
}

.related__title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 30px;
  color: #333;
}

/* Rating Stars */
.rating__group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.rating__star {
  font-size: 20px;
  color: black;
  transition: color 0.3s ease;
  cursor: pointer;
}

.rating__star.active {
  color: goldenrod;
}

.rating__star:hover {
  transform: scale(1.2);
}

.rating__star i {
  font-size: 20px;
  color: coral;
}

/* Responsive */

@media only screen and(width < 533px) {
  .product__images {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .product__details h2 {
    font-size: 1.3rem;
  }

  .rating__group {
    column-gap: 1rem !important;
  }

  .product__images {
    justify-content: center;
    margin-right: 10%;
  }

  .product__images img {
    width: 80px;
    height: 80px;
  }

  .selected-image img {
    width: 100%;
    height: auto;
    max-height: 300px;
  }

  .review__form {
    width: 90%;
  }

  .review__wrapper ul .review__item {
    width: 97.9%;
  }
}

@media only screen and (min-width: 534px) {
  .product__details {
    gap: 20px;
    align-items: center;
  }

  .product__details h2 {
    font-size: 1.4rem;
  }
  .product__images {
    margin-left: 10%;
  }
  .product__images .selected-image {
    width: 100%;
  }

  .row{
    margin-left: 20px;
  }

  .rating__group {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .rating__star {
    font-size: 25px;
  }

  .review__form {
    width: 50%;
    margin-top: 70px;
  }
}

@media only screen and (min-width: 992px) {
  .product__details {
    margin-left: 90px;
  }

  .product__images {
    flex-basis: 40%;
    align-self: flex-start;
    margin-left: 0%;
  }
}

@media only screen and (min-width: 1400px) {
  .product__details {
    margin-left: 0px;
  }

  .product__images {
    margin-left: 30px;
  }

  .product__images .selected-image {
    width: 100%;
  }
}
