.sidebar {
  width: 300px;
  height: 100vh;
}

.sidebar-container {
  display: flex;
  height: 100%;
  width: 100%;
  transition: margin-left 0.3s ease;
}

.sidebar-visible {
  margin-left: 0;
}

.profile {
  padding: 20px;
  display: none;
}

.profile__toggle {
  font-size: 2rem;
  color: var(--primary-color);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.profile-sidebar {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
  height: 100%;
  transition: transform 0.3s ease;
}

.profile-sidebar h4 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  position: relative;
  font-family: sans-serif;
  font-weight: 600;
  color: #707b8e;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  padding-top: 30px;
}

.profile-sidebar h4::before {
  content: "";
  position: absolute;
  bottom: -1.4px;
  left: 0;
  width: 25px;
  height: 2px;
  background-color: rgb(84, 69, 161);
}

.profile-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-sidebar__link {
  margin-bottom: 5px;
}

.sub-item-icon {
  margin-right: 5px;
}

.sub-item-display {
  margin-left: 5px;
  margin-top: 2px;
}

.profile-sidebar__link a {
  color: #333;
  text-decoration: none;
  display: block;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.profile-sidebar__link a:hover {
  background-color: #e9e9e9;
  color: rgb(84, 69, 161);
  font-weight: 500;
}

.active__admin-menu {
  background-color: #ccc;
  font-weight: bold;
  color: rgb(84, 69, 161);
}

@media (max-width: 768px) {
  .profile-sidebar {
    transform: translateX(-100%);
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    margin-left: 20px;
  }

  .profile__toggle {
    display: block;
    transition: transform 0.3s ease;
  }

  .profile__toggle i {
    font-style: 1.1rem !important;
  }

  .profile-sidebar {
    position: absolute;
    left: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    transition: transform 0.3s ease;
  }

  .sidebar {
    width: 50px;
    transition: width 0.3s ease;
    height: 50px;
  }
  .sidebar-container {
    width: 100%;
  }

  .sidebar-visible .profile-sidebar {
    transform: translateX(0);
  }
}
