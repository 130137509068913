.table {
  width: 100%;
}

.table thead th {
  color: var(--primary-color);
}

.table tr td img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

td i.delete-icon {
  cursor: pointer;
}

.cart-summary {
  margin-top: 2rem;
}

.cart-item-image {
  width: 100px;
  height: auto;
}

@media (max-width: 767px) {
  .table {
    display: block;

    width: 100%;
  }

  .table td,
  .table th {
    font-size: 0.8rem;
  }

  .cart-summary {
    margin-top: 2rem;
  }

  .cart-item {
    display: flex;
    align-items: center;
  }

  .cart-item-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 1rem;
  }
}
