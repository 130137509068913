.footer {
  padding-top: 60px;
  padding-bottom: 30px;
  background: #253b80;
  color: rgba(255, 255, 255, 0.735);
}

.footer__text {
  line-height: 30px;
}

.footer__quick-links ul li {
  background: transparent !important;
}

.footer__quick-links ul li a {
  color: rgba(255, 255, 255, 0.735);
}

.quick__links-title {
  margin-bottom: 20px;
  color: #fff;
  font-size: 1.1rem;
}

.footer__contact li span,
.footer__contact li p {
  font-size: 0.9rem;
}

.footer__contact li span {
  font-size: 1.2rem;
}

.footer__contact li p {
  margin-bottom: 10px;
}

.footer__contact li:last-child p {
  margin-bottom: 0;
}

.footer__contact li span,
.footer__contact li p {
  color: rgba(255, 255, 255, 0.735);
}

.footer__contact {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__contact li {
  flex-basis: 50%;
  margin-bottom: 15px;
}

.footer__contact li p {
  margin-bottom: 5px;
}

.footer__contact li span {
  display: block;
  margin-bottom: 5px;
}

.footer__contact li:last-child {
  flex-basis: 100%;
}

.footer__contact li:last-child p {
  margin-bottom: 0;
}

.footer__contact li:last-child span {
  margin-bottom: 0;
}

.footer__contact li:nth-child(even) {
  text-align: right;
}

.footer__contact li:nth-child(even) span {
  text-align: right;
}

.footer__contact li:nth-child(even) p {
  text-align: right;
}

.footer__contact li:nth-child(even) i {
  float: right;
}

.footer__contact i {
  margin-right: 5px;
  vertical-align: middle;
}

.footer__contact i:last-child {
  margin-right: 0;
}

.footer__contact i:before {
  font-size: 1.2rem;
}

.footer__contact li:last-child i:before {
  font-size: 1.4rem;
}

.footer__contact li:last-child span {
  display: block;
}

.footer__contact li:last-child i {
  margin-right: 0;
  float: none;
}

.footer__contact li:last-child p,
.footer__contact li:last-child span {
  text-align: center;
}

.footer__contact li:last-child span {
  margin-bottom: 5px;
}

.footer__contact li:last-child p {
  margin-bottom: 0;
}

.footer__contact li:last-child i:before {
  font-size: 1.2rem;
}

.footer__contact li:last-child i:last-child:before {
  font-size: 1.3rem;
}

.footer__contact li:last-child i:first-child:before {
  font-size: 1.1rem;
}

.footer__contact li:last-child i {
  margin: 0;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.footer__contact li:last-child i:last-child {
  margin-right: 0;
}

.footer__contact li:last-child i:before {
  vertical-align: middle;
  margin-right: 5px;
}

.footer__quick-links ul li:first-child {
  margin-top: 0;
}

.footer__quick-links ul li {
  margin-bottom: 8px;
}

.link__logo {
  display: flex;
}

.logo_footer {
  display: flex;
  align-items: center;
  width: 120px;
  height: 60px;
  /* filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(431%)
    hue-rotate(180deg) brightness(100%) contrast(96%);
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-pixelated;
  image-rendering: pixelated; */
}

.logo_footer img {
  image-rendering: pixelated;
}

.logo-text-footer {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  margin-top: 15px;
}

.logo-text-footer .star-footer {
  font-size: 1.2rem;
  font-weight: 700;
}

.logo-text-footer .furniture-footer {
  font-size: 0.9rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer__contact {
    flex-direction: column;
  }

  .footer__contact li {
    flex-basis: auto;
    margin-bottom: 10px;
    text-align: center;
  }

  .footer__contact li span,
  .footer__contact li p {
    text-align: center;
  }

  .footer__contact li:last-child {
    flex-basis: auto;
  }

  .footer__contact li:last-child p {
    margin-bottom: 5px;
  }

  .footer__quick-links ul li {
    margin-bottom: 5px;
  }

  .link__logo {
    justify-content: center;
  }

  .logo-text-footer {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
}
