.display_image {
  display: flex;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: 1px solid;
  border-radius: 10px;
  font-weight: 700;
}

.display_image input {
  background-color: transparent;
}
