.auth__form {
  background: #253b80;
  padding: 40px;
  border-radius: 5px;
}

.auth__btn {
  background: #fff !important;
  color: var(--primary-color);
  font-weight: 600;
}

.auth__form p {
  margin-top: 30px;
}

.auth__form p a {
  color: rgba(255, 255, 255, 0.735);
}

.auth__form input[type="file"] {
  color: #fff;
  cursor: pointer;
}

.password-input {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  top: 55%;
  right: 15px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: grey;
}

.password-toggle-btn:focus {
  outline: none;
}

.password-toggle-btn:hover {
  color: #121158;
}

.remember__me {
  display: flex;
  color: rgb(242, 238, 238);
  align-items: flex-start;
}

.remember__me input {
  margin-right: 5px;
}
