.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background: #253b80;
}

.nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  width: 60px;
  height: 50px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(431%)
    hue-rotate(180deg) brightness(100%) contrast(96%);
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-pixelated;
  image-rendering: pixelated;
}

.logo img {
  image-rendering: pixelated;
  margin-top: 9px;
  height: 70px;
}

.logo-text {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 7px;
}

.logo-text .star {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: -1.4rem; /* Adjust the negative margin as needed */
}

.logo-text .furniture {
  font-size: 0.9rem;
  color: #b4a9a9;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
  margin-bottom: 0;
}

.nav__item a {
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.nav__icons {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
}

.nav__icons span i {
  font-size: 1.4rem;
  color: white;
  cursor: pointer;
}

.cart__icon,
.fav__icon {
  position: relative;
}

.badge {
  position: absolute;
  top: 25%;
  right: -8%;
  width: 15px;
  height: 15px;
  content: "";
  background: var(--primary-color);
  color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 600;
  z-index: 10;
}

.bed__menu {
  font-size: 1.3rem;
  color: var(--primary-color);
  display: none;
}

.nav__active {
  font-weight: 700 !important;
}

.sticky__header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 3px 3px 8px -3px #ddd;
  background: #253b80;
}

.second__navigation {
  background-color: #dae6ff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.second__menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
  margin-bottom: 0;
}

.second-nav__item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: rgb(229, 50, 107);
  background-color: lightblue;
}

.second-nav__item a {
  color: rgb(0, 91, 157);
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  font-size: large;
}

@media only screen and (max-width: 768px) {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.53);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    z-index: 99999;
    flex-direction: column;
    justify-content: center;
  }

  .active__menu {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .bed__menu {
    display: block;
    opacity: 1;
  }

  .bed__menu span i {
    font-style: 1.1rem !important;
  }

  .logo img {
    display: none;
  }

  .logo h1 {
    font-style: 1rem;
  }

  .fav__icon i,
  .cart__icon i {
    font-style: 1.2rem !important;
  }

  .nav__icons span img {
    width: 1.4rem;
    height: 1.4rem;
  }

  .header {
    width: 100%;
    height: 60px;
    line-height: 60px;
  }

  .nav__item a {
    color: black;
  }

  .second__navigation {
    height: 60px;
  }

  .second__menu {
    height: 60px;
    padding-left: 0;
    column-gap: 1.1rem;
  }

  .second-nav__item {
    height: 50px;
    width: 100%;
    padding: 2px;
  }

  .second-nav__item a {
    font-size: 1rem;
  }
}

.profile-container {
  position: relative;
}

.profile-trigger {
  padding-bottom: 10px;
}

.profile-trigger img {
  height: 30px;
  width: 30px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 200px;
  display: block;
}

.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active {
  padding-top: 20px;
  padding-bottom: 0px;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

h3 {
  width: 100%;
  /* text-align: center; */
  font-size: 17px;
  padding: 20px 0;
  font-weight: 600;

  color: var(--primary-color);
  line-height: 1.2rem;
}

h3 span {
  font-size: 12px;
  color: var(--secondary-color);
  font-weight: 500;
}

.dropdown-menu ul li {
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a {
  color: rgb(212, 33, 9);
  cursor: pointer;
}

.dropdown-menu ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.dropdownItem {
  display: flex;
  margin: -10px auto;
}

.dropdownItem img {
  max-width: 25px;
  max-height: 25px;
  margin-right: 0px;
  margin-left: -15px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a {
  margin-top: -23px;
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 14px;
  transition: var(--speed);
}
