/* CSS for Cart Page */

.section1 {
  padding-bottom: 20px;
}

.filter__widget select {
  height: 45px;
  padding: 10px 20px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  font-size: 1rem;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 1rem;
}

.search__box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
}

.search__box input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 10px;
}

.search__box span {
  color: var(--primary-color);
}

@media only screen and (min-width: 768px) {
  .filter__widget {
    margin-bottom: 30px;
  }

  .filter__widget select {
    padding: 7px 20px;
  }

  .search__box {
    /* Add your styles for larger screens here */
  }
}

@media only screen and (max-width: 767px) {
  .section1 {
    padding-bottom: 0;
  }

  .filter__widget {
    display: grid;
    padding: 10px;
  }

  .filter__widget select option {
    font-size: 0.8rem;
  }

  .search__box {
    margin: 10px 0;
    /* Add your styles for smaller screens here */
  }
}
