/* Default styles */
.admin__header {
  width: 100%;
  padding: 10px 0px;
  margin-top: 30px;
  background: #ffffff;
}

.admin__nav-top {
  width: 100%;
}

.admin__nav-wrapper-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 3rem;
}

.admin__nav-top-right img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
}

.admin__nav-top-right {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

.admin__nav-top-right span i {
  cursor: pointer;
  color: white;
}

.admin__nav-wrapper-top .logo h2 {
  font-size: 1.1rem;
  font-weight: 700;
  color: whitesmoke;
  width: 130px;
}

.admin__menu {
  width: 100%;
  height: 70px;
  line-height: 70px;
}

.admin__navigation {
  text-align: center;
}

.admin__menu-list {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
  justify-content: center;
}

.admin__menu-list .admin__menu-item a {
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
  color: black;
}

.admin__menu-item .active___admin-menu {
  background-color: rgb(37, 43, 96);
  color: #ffffff;
}

.admin__menu-list .admin__menu-item a.active___admin-menu {
  color: white !important;
}

.dashboard__menu {
  font-size: 1.5rem;
  color: var(--primary-color);
  display: none;
}
/* Responsive styles */
@media (max-width: 768px) {
  /* Adjust font sizes and spacing for smaller devices */
  .admin__navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.53);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .admin__menu-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background: #9caef6d8;
    z-index: 99999;
    flex-direction: column;
    justify-content: center;
  }

  .active__menu {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .dashboard__menu {
    display: block;
    opacity: 1;
  }

  .admin__nav-wrapper-top .logo h2 {
    font-size: 0.9rem;
    width: auto;
  }

  .admin__menu-list .admin__menu-item a {
    padding: 10px 15px;
  }

  .admin__menu-list {
    row-gap: 2.7rem;
  }
}

@media (max-width: 480px) {
  /* Adjust font sizes and spacing for mobile devices */
  .admin__nav-wrapper-top .logo h2 {
    font-size: 0.8rem;
  }

  .admin__menu-list {
    column-gap: 0.5rem;
  }

  .admin__navigation {
    text-align: start;
  }

  .admin__menu-list .admin__menu-item a {
    padding: 6px 10px;
  }
}
