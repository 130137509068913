.info-content__title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.info-card {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.info-card__content {
  display: flex;
  flex-wrap: wrap;
}

.info-card__item {
  width: 100%;
  margin-bottom: 10px;
}

.info-card__label {
  font-weight: 600;
  color: rgb(110, 110, 110);
}

.info-card__input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.info-card__dob {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-card__dob-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.info-card__image-container {
  position: relative;
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 5%;
  overflow: hidden;
}

.info-card__image {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.info-card__edit-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.info-card__edit-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.info-card-img__change {
  display: flex;
  margin-top: 10px;
  margin-left: 70px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .info-card {
    max-width: 400px;
    padding: 0px;
    border: none;
  }

  .info-content__title {
    font-size: 20px;
  }

  .info-card__image-container {
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;
    height: 70%;
    object-fit: none;
  }

  .info-card__image {
    width: 100%;
  }

  .info-card__edit-btn,
  .info-card-img__change {
    font-size: 10px;
    padding: 3px 8px;
  }
}
