/* Default styles */
.orders-container {
  margin: 20px;
  margin-top: 80px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.orders-container h2 {
  font-weight: 600;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
}

.order-table th,
.order-table td {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.order-table th {
  text-align: left;
  background-color: #f2f2f2;
}

.order-table tbody tr:hover {
  background-color: #f9f9f9;
}

.modal {
  position: relative;
  background-color: #fff;
  padding: 20px;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 5px;
}

.order-details h2 {
  margin-bottom: 10px;
}

.order-details p {
  margin-bottom: 5px;
}

.order-details button {
  margin-top: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
  /* Adjust margins and paddings for smaller devices */
  .orders-container {
    margin: 10px;
    margin-top: 60px;
    padding: 10px;
  }

  .order-table th,
  .order-table td {
    padding: 6px;
  }

  .modal {
    padding: 10px;
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  /* Adjust margins, paddings, and font sizes for mobile devices */
  .orders-container {
    margin: 5px;
    margin-top: 40px;
    padding: 5px;
  }

  .orders-container h2 {
    font-size: 1.2rem;
  }

  .order-table th,
  .order-table td {
    padding: 4px;
  }

  .modal {
    padding: 8px;
    max-width: 200px;
  }

  .order-details h2 {
    margin-bottom: 8px;
    font-size: 1rem;
  }

  .order-details p {
    margin-bottom: 3px;
    font-size: 0.9rem;
  }

  .order-details button {
    margin-top: 15px;
  }
}
